import request from '@/utils/request'

export function getProdServKeys () {
  return request({
    url: '/keys/prod-serv',
    method: 'get'
  })
}

export function getUnitKeys () {
  return request({
    url: '/keys/unit',
    method: 'get'
  })
}

export function getObjImpKeys () {
  return request({
    url: '/keys/obj-imp',
    method: 'get'
  })
}
